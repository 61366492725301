@import 'styles/colors.scss';

.tilesToggleModuleWrapper {
  -webkit-font-smoothing: antialiased;


  .titlesWrapper {
    padding: 20px 20px 0 20px;

    @media (min-width: 768px) {
      padding: 60px 70px 0 70px;
    }

    h1.title {
      font-family: Sharp Sans Display No2, serif;
      font-size: 44px;
      line-height: 88%;
      text-align: center;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        font-size: 64px;
        margin-bottom: 32px;
      }
    }

    h2.subTitle {
      letter-spacing: -1px;
      font-family: GT Alpina, serif;
      font-size: 28px;
      line-height: 114%;
      text-align: center;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        font-size: 40px;
      }
    }
  }

  .stickWrapper {
    position: sticky;
    top: 60px;
    z-index: 1;
    height: 75px;
    display: block;
    align-items: center;
    padding-top: 15.5px;
    text-align: center;
    margin-bottom: 20px;
    background: $white;

    @media (min-width: 768px) {
      top: 68px;
      height: 90px;
    }

    &.shadow {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
    }

    .toggleButtonWrapper {
      font-size: 14px;
      font-family: 'Graphik Web', 'sans-serif', serif;
      padding: 10px 0;
      position: relative;
      max-width: 343px;
      background: $primary-blue;
      height: 44px;
      border-radius: 100px;
      border: 1px;
      display: inline-block;
      width: 100%;
      text-align: center;
      @media (min-width: 768px) {
        padding: 13px 0;
        height: 60px;
        font-size: 20px;
        max-width: 500px;
      }

      .slidingBackground {
        position: absolute;
        top: 6%;
        width: 49%;
        height: 90%;
        background: $white;
        border-radius: 100px;
        transition: all 0.3s ease-in-out;
        transition-timing-function: cubic-bezier(0, .84, 1, .98);

        z-index: 0;

        &.left {
          left: 0;
          transform: translateX(1.5%);
        }

        &.right {
          left: 0;
          transform: translateX(102.5%);
        }

      }

      .singleCoverageButton {
        position: relative;
        color: $white;
        width: 50%;
        font-weight: normal;
        transition: all 0.3s ease-in-out;
        transition-timing-function: cubic-bezier(0, .84, 1, .98);

        &.active {
          font-weight: bold;
          color: $blue-ocean;
        }
      }
    }
  }

  .cobLobTilesWrapper {
    display: block;
    min-height: 65vh;
    padding: 0 20px;

    @media (min-width: 768px) {
      padding: 0 70px;
      min-height: 17vw;
    }

    .firstTilesWrapper,
    .secondTilesWrapper {
      display: block;
      @media (min-width: 768px) {
        gap: 24px;
        display: flex;
        justify-content: center;
      }

      .tileWrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: row;
        border: 2px solid $bright-gray;
        border-radius: 12px;
        margin-bottom: 20px;
        padding: 15px 20px;
        transition: all 0.3s ease-in-out;

        &:hover {
          border: 2px solid $primary-blue;

          .tileTextWrapper {
            .tileCta {
              @media (min-width: 1200px) {
                background: $primary-blue;
                color: $white;
              }
            }
          }

        }

        @media (min-width: 768px) {
          width: 20%;
          max-width: 236px;
          display: block;
        }
        @media (min-width: 1200px) {
          min-height: 280px;
        }

        .tileImageWrapper {
          position: relative;
          @media (min-width: 768px) {
            margin-bottom: 20px;
            max-height: 70px;
            display: flex;
          }
          @media (min-width: 1200px) {
            max-height: 120px;
          }

          &.lobImageWrapper {
            width: 24%;
            @media (min-width: 768px) {
              width: 100%;
            }

            img {
              @media (min-width: 768px) {
                width: 100%;
                height: auto;
              }
            }
          }

          &.cobImageWrapper {
            width: 36%;
            @media (min-width: 768px) {
              width: 100%;
            }

            img {
              object-fit: cover;
              width: 120px;
              height: 60px;
              border-radius: 8px;
              @media (min-width: 768px) {
                width: 100%;
                height: auto;
              }
            }
          }
        }

        .tileTextWrapper {
          width: 100%;
          display: block;
          padding-left: 30px;
          font-family: 'Graphik Web', 'sans-serif', serif;
          font-size: 16px;
          @media (min-width: 768px) {
            width: 100%;
            padding: 0;
            text-align: center;
          }

          h3.tileTitle {
            display: block;
            font-weight: bold;

            @media (min-width: 768px) {
              margin-bottom: 20px;
            }
            @media (min-width: 768px) and (max-width: 1200px) {
              font-size: 1.2vw;
            }
          }

          .tileCta {
            transition: all 0.3s ease-in-out;
            color: $primary-blue;
            display: block;
            @media (min-width: 1200px) {
              padding: 0.8vw 1vw;
              background: $white;
              margin-bottom: 20px;
              border: 1px solid $primary-blue;
              border-radius: 100px;
            }
            @media (min-width: 768px) and (max-width: 1200px) {
              font-size: 1vw;
            }
            @media (min-width: 1200px) and (max-width: 1900px) {
              font-size: 1.05vw;
            }
          }
        }
      }
    }
  }

  .returnToQuoteButton {
    font-family: 'Graphik Web', 'sans-serif', serif;
    color: $primary-blue;
    text-align: center;
    display: block;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      font-size: 20px;
    }
  }
}
