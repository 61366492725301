.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.contentAlignLeft {
  display: flex;
  justify-content: start;
}

.contentAlignRight {
  display: flex;
  justify-content: flex-end;
}

.contentAlignCenter {
  display: flex;
  justify-content: center;
}
