
.variableColumnComponent {
  padding-bottom: 6.4vw;
}

@mixin flex-columns($numOfColumnsPerRow) {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;


  margin-left: 4.267vw;
  margin-right: 4.267vw;
  row-gap: 2.083vw;
  column-gap: 2.083vw;

  > * {
    border-radius: 3.2vw;
    padding: 4.8vw;

    flex: 0 0 calc((100% / $numOfColumnsPerRow) - (($numOfColumnsPerRow - 1) * 2.083vw));
    max-width: calc((100% / $numOfColumnsPerRow) - (($numOfColumnsPerRow - 1) * 2.083vw));
  }

  @media (min-width: 640px) {
    margin-left: 7.813vw;
    margin-right: 7.813vw;
    row-gap: 3.125vw;
    column-gap: 3.125vw;

    > * {
      border-radius: 1.563vw;
      padding: 2.344vw;

      flex: 0 0 calc((100% / $numOfColumnsPerRow) - (($numOfColumnsPerRow - 1) * 3.125vw));
      max-width: calc((100% / $numOfColumnsPerRow) - (($numOfColumnsPerRow - 1) * 3.125vw));
    }
  }

  @media (min-width: 1024px) {
    margin-left: 5.556vw;
    margin-right: 5.556vw;
    row-gap: 2.222vw;
    column-gap: 2.222vw;

    > * {
      border-radius: 0.833vw;
      padding: 1.667vw;

      flex: 0 0 calc((100% / $numOfColumnsPerRow) - (($numOfColumnsPerRow - 1) * 2.222vw));
      max-width: calc((100% / $numOfColumnsPerRow) - (($numOfColumnsPerRow - 1) * 2.222vw));
    }
  }

  @media (min-width: 1920px) {
    margin-left: 80px;
    margin-right: 80px;
    row-gap: 32px;
    column-gap: 32px;

    > * {
      border-radius: 12px;
      padding: 24px;
      flex: 0 0 calc((100% / $numOfColumnsPerRow) - (($numOfColumnsPerRow - 1) * 32px));
      max-width: calc((100% / $numOfColumnsPerRow) - (($numOfColumnsPerRow - 1) * 32px));
    }
  }
}

.column1 {
  @include flex-columns(1);
}

.column2 {
  @include flex-columns(1);

  @media (min-width: 640px) {
    @include flex-columns(2);
  }
}

.column3 {
  @include flex-columns(1);

  @media (min-width: 640px) {
    @include flex-columns(3);
  }
}

.column4 {
  @include flex-columns(1);

  @media (min-width: 640px) {
    @include flex-columns(2);
  }

  @media (min-width: 1024px) {
    @include flex-columns(4);
  }
}

.headline {
  font-family: "GT Alpina", serif;
  font-weight: normal;
  line-height: 115%;
  letter-spacing: -0.03em;


  margin-top: 6.4vw;
  margin-bottom: 3.2vw;
  font-size: 7.467vw;

  @media (min-width: 640px) {
    margin-top: 4.16vw;
    margin-bottom: 1.563vw;
    font-size: 3.646vw;
  }

  @media (min-width: 1024px) {
    margin-top: 3.33vw;
    margin-bottom: 1.667vw;
    font-size: 2.778vw;
  }

  @media (min-width: 1920px) {
    font-size: 40px;
  }
}

.innerHeadline {

  @media (min-width: 1024px) {
    max-width: 64.444vw;
  }

  @media (min-width: 1920px) {
    max-width: 1000px;
  }
}

.subHeadline {
  font-family: 'Graphik Web', sans-serif;
  line-height: 140%;
  font-weight: 600;
  letter-spacing: -0.01em;


  margin-bottom: 6.4vw;
  font-size: 4.267vw;

  @media (min-width: 640px) {
    margin-bottom: 3.125vw;
    font-size: 2.083vw;
  }

  @media (min-width: 1024px) {
    margin-bottom: 2.222vw;
    font-size: 1.389vw;
  }

  @media (min-width: 1920px) {
    font-size: 20px;
    margin-bottom: 32px;
  }
}

.imageInnerContainer {

  max-width: 75%;

  @media (min-width: 640px) {
    max-width: 75%;
  }

}

.imageContainer {


  margin-bottom: 6.4vw;

  @media (min-width: 640px) {
    margin-bottom: 3.125vw;
  }

  @media (min-width: 1920px) {
    margin-bottom: 24px;
  }
}

.columnHeadline {
  font-family: 'Graphik Web', sans-serif;
  line-height: 140%;
  font-weight: 600;
  letter-spacing: -0.01em;


  margin-bottom: 3.2vw;
  font-size: 2.083vw;

  @media (min-width: 640px) {
    margin-bottom: 0.833vw;
    font-size: 2.083vw;
  }

  @media (min-width: 1024px) {
    margin-bottom: 1.563vw;
    font-size: 1.389vw;
  }

  @media (min-width: 1920px) {
    font-size: 20px;
    margin-bottom: 12px;
  }
}

.text {
  font-family: 'Graphik Web', sans-serif;
  line-height: 140%;
  font-weight: 400;
  letter-spacing: -0.01em;


  font-size: 2.083vw;

  @media (min-width: 640px) {
    font-size: 2.083vw;
  }

  @media (min-width: 1024px) {
    font-size: 1.389vw;
  }

  @media (min-width: 1920px) {
    font-size: 20px;
  }
}

.ctaColumnButtonContainer {
  display: flex;
  justify-content: center;


  margin-top: 6.4vw;

  @media (min-width: 640px) {
    margin-top: 3.125vw;
  }

  @media (min-width: 1920px) {
    margin-top: 24px;
  }
}

.ctaButtonContainer {
  display: flex;
  justify-content: center;


  margin-top: 3.125vw;

  @media (min-width: 640px) {
    margin-top: 3.125vw;
    margin-bottom: 4.167vw;
  }

  @media (min-width: 1024px) {
    margin-top: 2.222vw;
    margin-bottom: 3.333vw;
  }

  @media (min-width: 1920px) {
    margin-top: 32px;
  }
}

