$white: #FFFFFF;
$primary-blue: #0075ff;
$primary-blue-hover: #00d7ff;
$primary-yellow: #ffdf59;
$primary-yellow-hover: #e8cd59;
$secondary-yellow: #ffd36a;
$next-yellow: #DCF000;
$lemon-yellow: #FFFF5E;
$light-lemon-yellow: #FFFFD7;
$primary-sky: #94FAFF;
$secondary-sky: #cafdff;
$tertiary-sky: #E5FEFF;
$primary-light-green: #d9f0ea;
$primary-text-green: #009E73;
$primary-green-hover: #00E650;
$next-green: #00E650;
$spring-green: #C2FFAB;
$primary-light-orange: #fbe9CC;
$primary-text-orange: #ed9100;
$bright-bg-gray: #F6F6F6;
$core-gray: #ced5da;
$core-light-gray: #231f20;
$core-light-gray-transparent: rgba(35, 31, 32, 0.75);
$core-tangarona-gray: #0e2b42;
$dark-blue: #4d5da9;
$core-dark-blue: #0092d0;
$core-gray-background: #f8f9fa;
$light-grey: #979797;
$extra-light-grey: #d4d4d8;
$ocean-blue: #0073b7;
$secondary-light-gray: #e4e8eb;
$secondary-grey: #707070;
$light-blue: #00bdf1;
$secondary-light-blue: #03c8ff;
$secondary-blue: #00a3e0;
$pagination-grey: rgba(14, 43, 66, 0.7);
$rose: #FFE6ED;
$next-pink: #FF32A0;
$violet: #AC00E7;
$orange-red: #FF4100;
$indian-red: #e24c4c;
$peach: #FFD2B9;
$bright-pastel-red: #f0a9a7;
$next-gray: #6D6E71;
$next-rose: #ff0047;
$blue-link: #307BF6;
$blue-ocean: #0075FF;
$lavender: #DCCBFF;
$bright-gray: #D1D3D4;
$brighter-gray: #f0f2f2;
$tooltip-black: #202327;
$lighter-grey: #F1F2F2;
$black: #231F20;
$grey-shadow-box: #002626;
